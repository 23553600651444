/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './styles.scss';
import IconTextLink from '../IconTextLink/index';
import Dropdown from '../Dropdown/index';

type Props = {
  content: Object,
  bannerType: string,
  imageRefClass: string,
  displayName: string,
  displayNameFund2: string,
  hasAction: boolean,
  shareIcon: boolean,
  shareTarget: string,
  editButton: boolean,
  onChangeHandler: () => void,
  onBlurHandler: () => void,
  actionHandler: () => void,
  handleEditButton: () => void,
  handleAddPaperIQ: () => void,
};

const Banner = ({ bannerType, displayName, imageRefClass, content,
  onChangeHandler, hasAction, actionText,
  actionHandler, hasEmailAction, emailText, emailHandler, shareIcon,
  shareTarget, editButton, handleEditButton, handleAddPaperIQ, shareLink, firmInformation }: Props) => {

  const [opened, setOpened] = useState(false);
  const [copied, setCopied] = useState(false);


  const renderDropdown = (id, value, title, displayName, size, isValid,
    errMessage, options, handler, blurHandler) => {
    const inputDataTemp = {
      id,
      value: value || '',
      displayName,
      title,
      size,
      hasError: !isValid,
      errorMessage: errMessage,
      options,
    };
    return (<Dropdown
      inputData={inputDataTemp}
      onChangeHandler={handler}
      onBlurHandler={blurHandler}
    />);
  };

  const handleClick = () => {
    if (!opened) {
      setOpened(true);
      document.addEventListener('click', handleOutsideClick, false);
    }
  };

  const handleOutsideClick = () => {
    setOpened(false);
    document.removeEventListener('click', handleOutsideClick, false);
  }

  const handleCopyShareLink = (type) => {

    if (type === 'stage') {
      navigator.clipboard.writeText(shareLink.stageUrl);
    } else {
      navigator.clipboard.writeText(shareLink.commonUrl);
    }
    
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000)
  }

  const displayNameFund3 = content && content.displayNameFund3;
  const displayNameFund2 = content && content.displayNameFund2 ? 
    `${!displayNameFund3 ? ' and' : ',' } ${content.displayNameFund2} ${displayNameFund3 ? 'and' + displayNameFund3 : '' }` : '';
  const renderFundBanner = () => (
    <div className="banner-container">
      <h3 className="banner-title">{content.displayName}{displayNameFund2}</h3>
      <div className="menu-items">
        <ul className="menu-list">
            <li className="opt-content">
              <a className="hovered-option-tracking-sheet menu-items-text-color" href="#/" onClick={handleEditButton}>
                <i class="fa fa-edit"></i> Edit&nbsp;Fund
              </a>
            </li>
            <li className=""><a className="hovered-option-tracking-sheet menu-items-text-color" href="#/" onClick={handleAddPaperIQ}>
              <i className={'fa fa-files-o'} /> Add Paper IQ</a>
            </li>
             <li className="opt-content"><a className="hovered-option-tracking-sheet menu-items-text-color" href="#/" onClick={actionHandler}>
              <i className={'fa fa-download'} /> Download&nbsp;CSV</a>
            </li>
            <li>
              <span className="hovered-option-tracking-sheet menu-items-text-color copy-share-link" onClick={() => handleCopyShareLink('common')}>
                <i className={'fa fa-share-square-o'} /> {!copied ? 'Copy Share Link'  : 'Link Copied!'}
              </span>
            </li>
            { (typeof window !== 'undefined' && (window.location.href.includes('fundtracker-stage.herokuapp.com') ||
                window.location.href.includes('flabprojects.com'))) && <li>
              <span className="hovered-option-tracking-sheet menu-items-text-color copy-share-link" onClick={() => handleCopyShareLink('stage')}>
                <i className={'fa fa-share-square-o'} /> {!copied ? 'Copy Share Stage Link'  : 'Link Copied!'}
              </span>
            </li>}
          </ul>
        </div>
    </div>
  );

  const renderInformationBanner = (content, hasAction, actionText, actionHandler, hasEmailAction, emailText, emailHandler, shareIcon, shareTarget, editButton, handleEditButton, bannerSelector) => (
    <div className="color-white text-align-center admin-pagehead">
      {editButton &&
        <span className="right-position-edit-button"><b><a href="#/" onClick={handleEditButton}>Edit</a></b></span>}
      {!bannerSelector && <h3>
        {content.displayName}
        {shareIcon ?
          <a href="#/" data-toggle="modal" data-target={`#${shareTarget}`} className="share-btn banner-share-icon">
            <i className={'fa fa-share-square-o color-light-black banner-share-icon'} aria-hidden="true" />
          </a> : null}
      </h3>}
      <br />

      {hasEmailAction &&
        <span>
          <span className="glyphicon glyphicon-envelope" /> &nbsp;
          <a className="color-white" href="#/" onClick={emailHandler}>
            {emailText !== '' ? emailText : 'Email'}
          </a>
        </span>}
      <br />

      {hasAction &&
        <div>
          <br />
          <a className="color-white" href="#/" onClick={actionHandler}>
            <u>{actionText !== '' ? actionText : 'Edit'}</u>
          </a>
        </div>}

     {!bannerSelector && <hr className="background-color-light-gray" />}

      <div className="admin-pagehead-contact">
        {(content.contactInfo[0]) &&
          <IconTextLink
            data={{
              icon: 'fa-envelope',
              text: content.contactInfo[0],
              color: 'color-white',
              href: `mailto:${content.contactInfo[0]}`
            }}
          />}
        {(content.contactInfo[1]) &&
          <IconTextLink
            data={{
              icon: 'fa-phone',
              text: content.contactInfo[1],
              color: 'color-white',
              href: `mailto:${content.contactInfo[0]}`
            }}
          />}
      </div>
    </div>
  );

  const renderFirmSelectBanner = (selectId, value, title, firmName, displayName, selectOptions, isValid, errMessage, onChangeHandler, onBlurHandler, firmInformation) => (
    <div className="color-white text-align-center">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 center-block">
          {!firmName ? <h5>{title}</h5> : <h3>{firmName}</h3>}
          {renderDropdown(selectId, value, title, displayName, 'large', isValid, errMessage, selectOptions, onChangeHandler, onBlurHandler)}
          {firmInformation  && renderInformationBanner(firmInformation, false, '', () => { }, false, '', () => { }, false, '', false, () => { }, true)}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`banner ${bannerType === 'fund' && 'fit-banner'} ${imageRefClass}`}>
      <div className={`container ${bannerType !== 'fund' && 'pull-70px'} banner-container`}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {(bannerType === 'action') && renderFirmSelectBanner(content.selectId, content.value, content.title, content.firmName, displayName, content.selectOptions, content.isValid, content.errorMessage, onChangeHandler, () => {}, firmInformation)}

            {(bannerType === 'fund') && (content && content.displayName) && renderFundBanner()}

            {(bannerType === 'info') && renderInformationBanner(content, hasAction, actionText, actionHandler, hasEmailAction, emailText, emailHandler, shareIcon, shareTarget, editButton, handleEditButton)}

            {(bannerType !== 'action' && bannerType !== 'info' && bannerType !== 'fund') && content}
          </div>
        </div>
      </div>
    </div>
  )
};

Banner.defaultProps = {
  bannerType: 'image', // image | action | info
  imageRefClass: '',
  displayName: '',
  content: {},
  hasAction: false,
  actionText: '',
  hasEmailAction: false,
  emailText: '',
  shareIcon: false,
  editButton: false,
  shareTarget: '',
  onChangeHandler: () => { },
  onBlurHandler: () => { },
  actionHandler: () => { },
  emailHandler: () => { },
  handleEditButton: () => { },
  handleAddPaperIQ: () => { },
};

export default Banner;

